<template>
  <v-container v-if="!!customerEvaluation" class="px-0">
    <h2 class="d-flex justify-center pa-2 pb-5">
      {{ customerEvaluation.title }}
    </h2>

    <v-row class="d-flex custom-flex-no-wrap">
      <v-col v-if="mdAndUp" class="d-flex" cols="auto">
        <CardCustomerRating
          :number-of-stars="customerEvaluation.total_score"
          :review-text="customerEvaluation.total_description"
          :trusted-logo-url="customerEvaluation.trusted_logo ?? ''"
          :verified-checkmark="false"
          :fixed-trusted-review="true" />
      </v-col>

      <v-col cols="12" md="9" lg="11">
        <v-slide-group class="customer-reviews" center-active show-arrows :mobile="!mdAndUp">
          <v-slide-group-item
            v-for="(review, index) of customerEvaluation.displayed_reviews"
            :key="index"
            v-slot="{ toggle }">
            <CardCustomerRating
              :number-of-stars="review.cde_review_id.rating"
              :review-date-string="review.cde_review_id.date"
              :review-text="review.cde_review_id.description ?? ''"
              :click-event="toggle" />
          </v-slide-group-item>
        </v-slide-group>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup lang="ts">
import { useDisplay } from 'vuetify'
import type { ReviewWrapper } from '~/types/types'

const { mdAndUp } = useDisplay()

const props = defineProps<{
  customerEvaluation: ReviewWrapper
}>()

const { customerEvaluation } = toRefs(props)
</script>

<style lang="scss" scoped>
.customer-reviews {
  :deep(.v-btn) {
    background: transparent;
  }
}

.custom-flex-no-wrap {
  flex-wrap: nowrap;
}
</style>
